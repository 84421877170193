import { theme, useResponsive } from "@product/scmp-sdk";
import { useHover, useInterval } from "ahooks";
import { useAtomValue } from "jotai";
import type { RefObject } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIdle } from "react-use";

import type { AdUnit } from "scmp-app/components/advertisement/ad-slots/types";
import { isNativeAdUnit } from "scmp-app/components/advertisement/ad-slots/types";
import * as gpt from "scmp-app/components/advertisement/google-publisher-tag/hooks";
import { appAtom } from "scmp-app/lib/app/atoms";
import { useCurrentArticleIds } from "scmp-app/lib/current-item";
import { useParsedCookie } from "scmp-app/lib/hooks";

export type AutoRefreshParameters = {
  adUnit: AdUnit;
  manualRefreshCount: number;
  options: {
    intervalRefreshOptions: {
      isDisable?: boolean;
      slotIntersectionEntry: IntersectionObserverEntry | null;
      slotReference: null | RefObject<HTMLElement>;
    };
    scrollBackRefreshOptions: {
      isDisable?: boolean;
      slotArticleEntityId?: string;
      slotIntersectionEntry: IntersectionObserverEntry | null;
    };
  };
  slot: googletag.Slot | null;
};
export const useAutoRefresh = ({
  adUnit,
  manualRefreshCount,
  options,
  slot,
}: AutoRefreshParameters) => {
  const { intervalRefreshOptions, scrollBackRefreshOptions } = options;

  const shouldEnableAutoRefresh = useShouldEnableAutoRefresh(adUnit, slot);

  const { scrollBackRefreshCount } = useScrollBackRefresh({
    isEnable: !scrollBackRefreshOptions.isDisable && shouldEnableAutoRefresh,
    slot,
    slotArticleEntityId: scrollBackRefreshOptions.slotArticleEntityId,
    slotIntersectionEntry: scrollBackRefreshOptions.slotIntersectionEntry,
  });

  const { intervalRefreshCount } = useIntervalRefresh({
    isEnable: !intervalRefreshOptions.isDisable && shouldEnableAutoRefresh,
    slot,
    slotIntersectionEntry: intervalRefreshOptions.slotIntersectionEntry,
    slotReference: intervalRefreshOptions.slotReference,
  });

  // Cspell:ignore adrefresh adrefresh_scrollback
  const totalRefreshCount = manualRefreshCount + intervalRefreshCount + scrollBackRefreshCount;

  useEffect(() => {
    if (!shouldEnableAutoRefresh) return;
    slot?.updateTargetingFromMap({
      adrefresh: totalRefreshCount.toString(),
      adrefresh_event: manualRefreshCount.toString(),
      adrefresh_interval: intervalRefreshCount.toString(),
      adrefresh_scrollback: scrollBackRefreshCount.toString(),
    });
  }, [
    intervalRefreshCount,
    manualRefreshCount,
    scrollBackRefreshCount,
    shouldEnableAutoRefresh,
    slot,
    totalRefreshCount,
  ]);

  return { totalRefreshCount };
};

function useShouldEnableAutoRefresh(adUnit: string, slot: googletag.Slot | null) {
  const { advertisementCampaignConfig, advertisementConfig, ipGeoLocation } = useAtomValue(appAtom);
  const renderedInfo = gpt.useRenderSlotInfo(slot);

  const isNativeAd = isNativeAdUnit(adUnit);

  // To make testing easier since development environment need to be access in internal network thus can't use vpn
  const { value: shouldForceAllowAdSlotAutoRefreshCountry } = useParsedCookie(
    "shouldForceAllowAdSlotAutoRefreshCountry",
    raw => raw === "true",
    {
      initializeWithValue: true,
    },
  );
  const isDisabledCountry =
    !shouldForceAllowAdSlotAutoRefreshCountry &&
    advertisementConfig.autoRefresh.disabledCountries.includes(
      ipGeoLocation?.result?.country ?? "",
    );

  const isDisabledLineItem =
    renderedInfo?.lineItemId &&
    advertisementCampaignConfig.autoRefresh.disabledDirectCampaignLineItemIds.includes(
      renderedInfo.lineItemId,
    );

  const shouldEnableAutoRefresh = !isNativeAd && !isDisabledCountry && !isDisabledLineItem;

  return shouldEnableAutoRefresh;
}

function useIntervalRefresh(options: {
  isEnable?: boolean;
  slot: googletag.Slot | null;
  slotIntersectionEntry: IntersectionObserverEntry | null;
  slotReference: null | RefObject<HTMLElement>;
}) {
  const { isEnable, slot, slotIntersectionEntry, slotReference } = options;

  const { advertisementConfig } = useAtomValue(appAtom);
  const refreshIntervalMs = advertisementConfig.autoRefresh.refreshIntervalMs;
  const delayIntervalMs = 15_000;

  const [intervalRefreshCount, setIntervalRefreshCount] = useState(0);
  const [delay, setDelay] = useState<number | undefined>(refreshIntervalMs);
  const { maximumRefreshCount } = useMaximumRefreshCount(slot, intervalRefreshCount);

  const isHovering = useHover(isEnable ? slotReference : undefined);
  // Reset counters when slot change
  useEffect(() => {
    setIntervalRefreshCount(0);
  }, [slot]);

  // Trigger refresh for every interval
  useInterval(() => {
    // Delay when it's being hovered
    if (isHovering) {
      setDelay(delayIntervalMs);
      return;
    }

    setIntervalRefreshCount(current => (current += 1));
    setDelay(refreshIntervalMs);
  }, delay);

  useEffect(() => {
    if (!isEnable) {
      setDelay(undefined);
      return;
    }

    if (intervalRefreshCount >= maximumRefreshCount) {
      // Stop when reach refresh count
      setDelay(undefined);
      return;
    }

    // Stop when not visible
    if (!slotIntersectionEntry?.isIntersecting) {
      setDelay(undefined);
      return;
    }

    setDelay(refreshIntervalMs);
  }, [
    isEnable,
    slotIntersectionEntry?.isIntersecting,
    intervalRefreshCount,
    maximumRefreshCount,
    refreshIntervalMs,
    setDelay,
  ]);

  return { intervalRefreshCount };

  function useMaximumRefreshCount(slot: googletag.Slot | null, intervalRefreshCount: number) {
    const { advertisementConfig } = useAtomValue(appAtom);
    const isDesktop = useResponsive(theme.breakpoints.up("desktop"), true);
    const maximumRefreshCountPerCycle = isDesktop
      ? advertisementConfig.autoRefresh.desktopMaximumRefreshLimit
      : advertisementConfig.autoRefresh.mobileMaximumRefreshLimit;
    const [refreshCycle, setRefreshCycle] = useState(1);

    // Reset for slot change
    useEffect(() => {
      setRefreshCycle(1);
    }, [slot]);

    // Resume refresh for desktop after reaching refresh count when user is active
    const isIdle = useIdle(advertisementConfig.autoRefresh.refreshIntervalMs / 2);
    useEffect(() => {
      if (!isDesktop) return;
      if (isIdle) return;

      if (intervalRefreshCount >= maximumRefreshCountPerCycle * refreshCycle) {
        setRefreshCycle(current => (current += 1));
      }
    }, [
      setRefreshCycle,
      isIdle,
      intervalRefreshCount,
      isDesktop,
      maximumRefreshCountPerCycle,
      refreshCycle,
    ]);

    return {
      maximumRefreshCount: maximumRefreshCountPerCycle * refreshCycle,
      resetMaximumRefreshCount: useCallback(() => setRefreshCycle(1), [setRefreshCycle]),
    };
  }
}

function useScrollBackRefresh(options: {
  isEnable: boolean;
  slot: googletag.Slot | null;
  slotArticleEntityId?: string;
  slotIntersectionEntry: IntersectionObserverEntry | null;
}) {
  const { isEnable, slot, slotArticleEntityId, slotIntersectionEntry } = options;

  const { advertisementConfig } = useAtomValue(appAtom);
  const minimalScrollAwayDurationMs = advertisementConfig.autoRefresh.minimalScrollAwayDurationMs;
  const [slotScrollBackRefreshCount, setSlotScrollBackRefreshCount] = useState(0);
  const [articleScrollBackRefreshCount, setArticleScrollBackRefreshCount] = useState(0);

  const { articleScrollAwayDurationMs, resetArticleScrollAwayTracking } =
    useArticleScrollAwayDuration(slotArticleEntityId);
  const { resetSlotScrollAwayTracking, slotScrollAwayDurationMs } =
    useSlotScrollAwayDuration(slotIntersectionEntry);

  useEffect(() => {
    if (!isEnable) return;

    if (slotScrollAwayDurationMs > minimalScrollAwayDurationMs) {
      setSlotScrollBackRefreshCount(current => (current += 1));
      resetArticleScrollAwayTracking();
    }
    if (articleScrollAwayDurationMs > minimalScrollAwayDurationMs) {
      setArticleScrollBackRefreshCount(current => (current += 1));
      resetSlotScrollAwayTracking();
    }
  }, [
    articleScrollAwayDurationMs,
    isEnable,
    minimalScrollAwayDurationMs,
    resetArticleScrollAwayTracking,
    resetSlotScrollAwayTracking,
    slotScrollAwayDurationMs,
  ]);

  useEffect(
    () => () => {
      setSlotScrollBackRefreshCount(0);
      setArticleScrollBackRefreshCount(0);
    },
    [slot],
  );

  const scrollBackRefreshCount = slotScrollBackRefreshCount + articleScrollBackRefreshCount;
  return { scrollBackRefreshCount };

  function useSlotScrollAwayDuration(intersection: IntersectionObserverEntry | null) {
    const { resetScrollAwayTracking, scrollAwayDurationMs } = useScrollAwayDuration(
      intersection?.isIntersecting,
    );
    return {
      resetSlotScrollAwayTracking: resetScrollAwayTracking,
      slotScrollAwayDurationMs: scrollAwayDurationMs,
    };
  }

  function useArticleScrollAwayDuration(slotArticleEntityId?: string) {
    const { currentArticleId } = useCurrentArticleIds();
    const isCurrentArticle =
      currentArticleId && slotArticleEntityId ? currentArticleId === slotArticleEntityId : false;

    const { resetScrollAwayTracking, scrollAwayDurationMs } =
      useScrollAwayDuration(isCurrentArticle);
    return {
      articleScrollAwayDurationMs: scrollAwayDurationMs,
      resetArticleScrollAwayTracking: resetScrollAwayTracking,
    };
  }

  function useScrollAwayDuration(isEnter?: boolean) {
    const lastEnterTimeMs = useRef<null | number>(null);
    const lastExitTimeMs = useRef<null | number>(null);

    useEffect(() => {
      if (isEnter) {
        lastEnterTimeMs.current = Date.now();
      } else if (lastEnterTimeMs.current) {
        lastExitTimeMs.current = Date.now();
        lastEnterTimeMs.current = null;
      }
    }, [isEnter]);

    const scrollAwayDurationMs =
      lastEnterTimeMs.current && lastExitTimeMs.current
        ? lastEnterTimeMs.current - lastExitTimeMs.current
        : 0;

    const resetScrollAwayTracking = useCallback(() => {
      lastEnterTimeMs.current = null;
      lastExitTimeMs.current = null;
    }, []);

    return { resetScrollAwayTracking, scrollAwayDurationMs };
  }
}
